@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --white: #ffffff;
  --white-100: #edf9ffe0;
  --white-200: #f9f9f9;
  --white-300: #f5f8fa;
  --white-400: #D0E0FF;

  --black: #000000;
  --black-50: #00000010;
  --black-100: #1e1e1e;
  --black-200: #0000001a;
  --black-300: #00000061;
  --black-350: #0000000f;
  --black-400: #0000000d;
  --black-500: #070707;
  --black-550: #5A71D01B;


  --primary-color: #01408b;
  --secondary-color: #f0f0f0;
  --pepsi-primary-color: #0047ba;
  --error-color: #ed1b24;

  --green-color: #18ce0f;
  --green-color-50: #49c607;
  --green-color-100: #40ad06;

  --blue-color: #23a0dd;
  --blue-color-100: #00a0dd;
  --blue-color-150: #00abff;
  --blue-color-200: #83d6ff;
  --blue-color-250: #83cbff;
  --blue-color-275: #a5ddf8;
  --blue-color-300: #f7fcffe0;
  --blue-color-400: #e5ecf8;
  --blue-color-500: #8cc2dd;
  --blue-color-550: #E5F5FC;
  --blue-color-600: #edf9ff;
  --blue-color-650: #dff4ff;
  --blue-color-675: #1147ba;
  --blue-color-700: #003177;
  --blue-color-725: #01408B;
  --blue-color-750: #01236f;
  --blue-color-775: #1147ba;
  --blue-color-800: #186f9a;
  --blue-color-900: #1874ad;

  --blue-search-color: #1890ff;

  --gray-color: #626262;

  --gray-50: #fcfcfd;
  --gray-100: #f8f8f8;
  --gray-150: #f2f2f2;
  --gray-175: #eeeeee;
  --gray-200: #e0e0e0;
  --gray-225: #e2e2e2;
  --gray-250: #e3e3e3;
  --gray-275: #e9e9e9;
  --gray-300: #d0d0d0;
  --gray-350: #c8c8c8;
  --gray-400: #adadad;
  --gray-425: #bababa;
  --gray-450: #b4b6b8;
  --gray-500: #989898;
  --gray-525: #888888;
  --gray-550: #8d8d8d;
  --gray-575: #636363;
  --gray-600: #686868;
  --gray-700: #404040;
  --gray-800: #4d4d4d;
  --gray-850: #5A5A5A;
  --gray-900: #3c3c3c;

  --voilet: #83a9ff;

  --yellow: #af6d00;
  --yellow-100: #764704;
  --yellow-200: #ffe0a5;
  --yellow-300: #ffcb83;
  --yellow-400: #fff9ede0;
  --yellow-500: #fff3e6;

  --red: #ff3131;
  --red-50: #ff4d4f;
  --red-100: #e3503e;
  --red-150: #e74c3c;

  /* Font Size Variables */
  --font-size-xs: 0.625rem;
  --font-size-sm: 0.75rem;
  --font-size-md: 0.875rem;
  --font-size-lg: 1rem;
  --font-size-xl: 1.125rem;
  --font-size-xxl: 1.25rem;
  --font-size-3xl: 2.25rem;

  /* Font Weight Variables */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-slight-medium: 425;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Form Elements */
  --form-label-font-size: var(--font-size-md);
  --form-label-text-color: var(--black-100);
  --form-field-error-color: var(--red);
  --form-label-font-weight: var(--font-weight-regular);
  --form-field-border-color: var(--gray-275);
  --form-field-focus-border-color: var(--blue-color-100);

  --form-field-checkbox-border-color: var(--gray-450);

  /* Switch */
  --switch-on-bg: var(--blue-color-100);
  --switch-off-bg: var(--gray-550);

  /* Tabs */
  --tabs-border-color: var(--gray-100);
  --tabs-border-width: 1px;
  --tabs-border-radius: 1px;
  --tabs-nav-bg: var(--white);
  --tabs-nav-hover-border-color: var(--gray-100);
  --tabs-nav-font-size: var(--font-size-md);
  --tabs-nav-active-text-color: var(--white);
  --tabs-nav-active-bg: var(--primary-color);

  /* Collapse */
  --collapse-header-text-font-size: var(--font-size-lg);

  /* Tags */
  --tag-bg-color: var(--blue-color-400);
  --tag-border-color: var(--gray-275);
  --tag-text-color: var(--pepsi-primary-color);
  --tag-text-color-secondary: var(--blue-color-900);

  /* Border Colors */
  --border-color: #0505050f;
  --border-blue-color: var(--blue-color-100);
  --default-border-color: #d9d9d9;
  --antd-default-border: #0505050f;

  /* Search Panel */
  --search-panel-height: 53px;

  /* Content */
  --main-content-wrapper-bg: var(--white-300);

  /* Font Family */
  --primary-font-family: 'Roboto', sans-serif;

  --header-bg: var(--primary-color);
  --header-menu-active-border: var(--blue-color-100);
  --header-menu-active-color: var(--white);

  --btn-primary-bg: var(--blue-color);
  --btn-primary-bg-hover: var(--blue-color-100);
  --btn-primary-text-color: var(--white);
  --btn-primary-box-shadow: 0 2px 3px var(--black-300);
  --btn-primary-disabled-bg: rgba(0, 0, 0, 0.6);

  --btn-link-text-color: var(--blue-color);
  --btn-link-hover-bg: var(--blue-color-400);

  --popover-box-shadow: 0 10px 13px var(--black-200);
  --popover-title-color: var(--blue-color-700);
  --popover-title-font-size: var(--font-size-xl);
  --popover-title-font-weight: var(--font-weight-regular);

  --filter-popover-category-title-text-color: var(--black-100);
  --filter-popover-category-title-border-color: var(--gray-225);

  --notification-success-bg: #f6ffef;
  --notification-success-text-color: var(--gray-color);
  --notification-success-icon-color: var(--green-color-100);

  --notification-error-bg: var(--red-150);
  --notification-error-text-color: var(--white);
  --notification-error-icon-color: var(--white);

  /* AG Grid */
  --ag-grid-header-border-bottom-color: #e4e4e4;
  --ag-grid-primary-theme-header-color: #f5f8fa;
  --ag-grid-unchecked-checkbox-border-color: #8e8e8e;

  /*Table Header background */
  --common-table-header-top-control-bg-color: #fcfcfd;

  /* Card Box style */
  --card-box-shadow: 0px 3px 6px #0000000f;

  /* Replay Status Count Icon Colors */
  --replay-resolved-color: #4cb64c;
  --replay-progress-color: #1e91cf;
  --replay-schedule-color: #0f51ce;
  --replay-pending-color: #f3a638;
  --replay-error-color: #e3503e;

  --replay-progress-bg-color: #23a8f0;
  --replay-schedule-bg-color: #6a9eff;

  /* box shadow */
  --box-shadow-color: #0000002b;
}
